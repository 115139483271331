import React from "react";
import flicha from "../../imgs/Flicha.png";
import "bootstrap/dist/css/bootstrap.css";
import { RiDownload2Line } from "react-icons/ri";

export default function Project({ src, ytLink, downloadLink, name }) {
  return (
    <div className="col-lg-3 col-md-6 col-sm-12 project">
      <img className="project_img" src={src} alt="" srcset="" />
      <div className="project_data">
        <img src={flicha} alt="" width="40px" srcset="" />
        <h3 className="project_heading" style={{ display: "inline" }}>{name}</h3>
      </div>
      <div className="project_links">
        {downloadLink != "" && <a href={downloadLink} target="_blank">
          <RiDownload2Line />
        </a>}
        <a href={ytLink} target="_blank">
          <img
            src="https://cdn3.iconfinder.com/data/icons/social-network-30/512/social-06-512.png"
            alt=""
            srcset=""
          />
        </a>
      </div>
    </div>
  );
}

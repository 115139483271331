import React from 'react'
import "./Projects.css"
import Project from './Project'
import coconut from "../../imgs/Coconut.png";
import village from "../../imgs/Retrievethevillage.png";
import parcour from "../../imgs/Parcour.png"
import airport from "../../imgs/AirportVR.jpg"
import astroid from "../../imgs/ARastroid.png"
import { RiImageLine } from 'react-icons/ri';

export default function Projects() {
  return (
    <div className='main'>
        <h1 className='title'>My Projects</h1>
        <h3 className='subtitle'>VR & AR Projects</h3>
        <div className='row project_content'>
           {
            vrProjects.map((project)=>{
                return <Project key={project.id} src={project.img}
                name={project.name}
                downloadLink={project.downloadLink}
                ytLink={project.ytLink} />
            })
           }
        </div>
        <h3 className='subtitle'>3D Games</h3>
        <div className='row project_content'>
           {
            games3d.map((project)=>{
                return <Project key={project.id} src={project.img}
                name={project.name}
                downloadLink={project.downloadLink}
                ytLink={project.ytLink} />
            })
           }
        </div>
    </div>
  )
}

const vrProjects = [
  {
    id:1,
    img: airport,
    name: "VR Traveling simulation prototype",
    downloadLink: "",
    ytLink:"https://youtu.be/1sucl2QFmMo"
  },
  {
    id:2,
    img: astroid,
    name: "AR Astroid",
    downloadLink: "https://drive.google.com/file/d/1nUeGg2GBwQOqHBCkcosLNZU-Duf6GJYx/view?usp=sharing",
    ytLink: "https://youtu.be/_6u0P54zitU",

  }
  

]

const games3d = [
  {
    id: 0,
    img: parcour,
    name: "Parcour Game Preview",
    downloadLink:"https://drive.google.com/file/d/1-Uw5bS8HFoPjqc4Nz356pwRZB48P-_en/view?usp=sharing",
    ytLink:"https://youtu.be/0a_M9mQwuFE?si=bTpltj4tr6zC2Xj6"
  },
  {
    id: 2,
    img: village,
    name: "Retreive The Village Protoype",
    downloadLink:"https://drive.google.com/file/d/12l2axUQb1D0OmOg2AC9TP-8qOswmwNEp/view?usp=sharing",
    ytLink:"https://youtu.be/ZhEE6J1Ko5E?si=5RbRhANNX6uedLj9"
  },
  {
    id: 3,
    img: coconut,
    name: "Coconut mobile game",
    downloadLink:"https://drive.google.com/file/d/1kIVLG1fMxF3lBxhuzdUHq_70UNQl2yjT/view?usp=sharing",
    ytLink:"https://youtu.be/zJoA68_XNw8?si=apPQIOjU6RiKJulX"
  }
]
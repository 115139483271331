import React from 'react';
import Logo from "../../imgs/Logo.png";
import "./LoadingScreen.css"

const LoadingScreen = () => {
  return (
    <div className="loadingScreen">
      <img src={Logo} className='image' alt="" srcset="" />
    </div>
  );
};


export default LoadingScreen;
